// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Address from "../../Address.bs.js";
import * as AppleMap from "../AppleMap.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";
import * as Style$ReactNative from "rescript-react-native/src/apis/Style.bs.js";
import * as Font$ReactMultiversal from "react-multiversal/src/Font.bs.js";
import * as Spacer$ReactMultiversal from "react-multiversal/src/Spacer.bs.js";
import * as SpacedView$ReactMultiversal from "react-multiversal/src/SpacedView.bs.js";

function PlacesMap$PlacesMapAddresses(Props) {
  var queryRef = Props.queryRef;
  var mapRef = React.useRef(undefined);
  var onMapReady = React.useCallback((function (map) {
          mapRef.current = map;
          var coords = new (mapkit.Coordinate)(AppleMap.defaultCoords.latitude, AppleMap.defaultCoords.longitude);
          map.setRegionAnimated(new (mapkit.CoordinateRegion)(coords, new (mapkit.CoordinateSpan)(AppleMap.defaultRegionSpan.latitudeDelta, AppleMap.defaultRegionSpan.longitudeDelta)));
        }), []);
  var addressesData = Belt_Option.map(Address.GetQuery.usePreloaded(queryRef, undefined).addresses, (function (addresses) {
          return addresses.data;
        }));
  React.useState(function () {
        
      });
  if (addressesData !== undefined) {
    return React.createElement(ReactNative.View, {
                style: {
                  flexDirection: "row",
                  height: Style$ReactNative.pct(100),
                  width: Style$ReactNative.pct(100)
                },
                children: null
              }, React.createElement(ReactNative.View, {
                    style: {
                      height: Style$ReactNative.pct(100),
                      width: Style$ReactNative.pct(20)
                    },
                    children: null
                  }, React.createElement(Spacer$ReactMultiversal.make, {
                        size: /* S */4
                      }), Belt_Array.map(addressesData, (function (addr) {
                          return Belt_Option.getWithDefault(Belt_Option.map(addr.attributes, (function (addrAttr) {
                                            return Belt_Option.getWithDefault(Belt_Option.map(addrAttr.organizers, (function (o) {
                                                              return Belt_Array.map(o.data, (function (organizer) {
                                                                            return React.createElement(ReactNative.Pressable, {
                                                                                        children: (function (param) {
                                                                                            return React.createElement(React.Fragment, undefined, React.createElement(SpacedView$ReactMultiversal.make, {
                                                                                                            vertical: /* S */4,
                                                                                                            horizontal: /* M */3,
                                                                                                            children: null
                                                                                                          }, React.createElement(ReactNative.Text, {
                                                                                                                children: Belt_Option.getWithDefault(Belt_Option.map(organizer.attributes, (function (oA) {
                                                                                                                            return oA.name;
                                                                                                                          })), null),
                                                                                                                style: [Font$ReactMultiversal.iosEm.title3]
                                                                                                              }), React.createElement(ReactNative.Text, {
                                                                                                                children: addrAttr.name,
                                                                                                                style: [Font$ReactMultiversal.iosEm.caption]
                                                                                                              }), Belt_Option.getWithDefault(Belt_Option.map(addrAttr.line1, (function (v) {
                                                                                                                      return React.createElement(ReactNative.Text, {
                                                                                                                                  children: v,
                                                                                                                                  style: [
                                                                                                                                    Font$ReactMultiversal.ios.caption,
                                                                                                                                    Font$ReactMultiversal.weight[300]
                                                                                                                                  ]
                                                                                                                                });
                                                                                                                    })), null), Belt_Option.getWithDefault(Belt_Option.map(addrAttr.line2, (function (v) {
                                                                                                                      return React.createElement(ReactNative.Text, {
                                                                                                                                  children: v,
                                                                                                                                  style: [
                                                                                                                                    Font$ReactMultiversal.ios.caption,
                                                                                                                                    Font$ReactMultiversal.weight[300]
                                                                                                                                  ]
                                                                                                                                });
                                                                                                                    })), null), React.createElement(ReactNative.Text, {
                                                                                                                children: null,
                                                                                                                style: [
                                                                                                                  Font$ReactMultiversal.ios.caption,
                                                                                                                  Font$ReactMultiversal.weight[300]
                                                                                                                ]
                                                                                                              }, addrAttr.postCode, " ", addrAttr.city)));
                                                                                          }),
                                                                                        onPress: (function (param) {
                                                                                            Belt_Option.map(mapRef.current, (function (m) {
                                                                                                    m.setRegionAnimated(new (mapkit.CoordinateRegion)(new (mapkit.Coordinate)(addrAttr.latitude, addrAttr.longitude), new (mapkit.CoordinateSpan)(AppleMap.zoomRegionSpan.latitudeDelta, AppleMap.zoomRegionSpan.longitudeDelta)));
                                                                                                  }));
                                                                                          }),
                                                                                        key: Belt_Option.getWithDefault(addr.id, addrAttr.name)
                                                                                      });
                                                                          }));
                                                            })), null);
                                          })), null);
                        }))), React.createElement(AppleMap.make, {
                    id: "AppleMapKitId",
                    width: "80%",
                    onMapReady: onMapReady,
                    annotationForCluster: (function (annotation) {
                        annotation.addEventListener("select", (function (param) {
                                console.log("Annotation *CLUSTER* CLICKED", annotation.memberAnnotations);
                              }));
                        return annotation;
                      }),
                    children: Belt_Array.map(addressesData, (function (addr) {
                            return Belt_Option.getWithDefault(Belt_Option.map(addr.attributes, (function (attr) {
                                              return React.createElement(AppleMap.MarkerAnnotation.make, {
                                                          coordinate: new (mapkit.Coordinate)(attr.latitude, attr.longitude),
                                                          options: {
                                                            title: attr.name,
                                                            clusteringIdentifier: "__cluster__",
                                                            color: "blue"
                                                          },
                                                          onPress: (function (param) {
                                                              console.log("Annotation CLICKED");
                                                            })
                                                        });
                                            })), null);
                          }))
                  }));
  } else {
    return null;
  }
}

var PlacesMapAddresses = {
  make: PlacesMap$PlacesMapAddresses
};

function PlacesMap(Props) {
  var addressesQueryRef = Address.useQueryRef_addresses(undefined);
  if (addressesQueryRef !== undefined) {
    return React.createElement(PlacesMap$PlacesMapAddresses, {
                queryRef: Caml_option.valFromOption(addressesQueryRef)
              });
  } else {
    return null;
  }
}

var make = PlacesMap;

export {
  PlacesMapAddresses ,
  make ,
}
/* react Not a pure module */
