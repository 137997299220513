// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var token = process.env.NEXT_PUBLIC_APPLE_MAPKITJS_TOKEN;

var initialised = {
  contents: false
};

function ensureInitialized(callback) {
  if (initialised.contents) {
    return Curry._1(callback, undefined);
  } else {
    initialised.contents = true;
    mapkit.init({
          authorizationCallback: (function (done) {
              Curry._1(done, token);
              Curry._1(callback, undefined);
            })
        });
    return ;
  }
}

function useMap(id, onMapReady, onMapDestroy, annotationForCluster, param) {
  var initialized = React.useRef(false);
  React.useEffect((function () {
          if (initialized.current) {
            return ;
          }
          initialized.current = true;
          var mapRef = {
            contents: undefined
          };
          ensureInitialized(function (param) {
                var m = new (mapkit.Map)(id);
                mapRef.contents = m;
                Belt_Option.map(annotationForCluster, (function (a) {
                        m.annotationForCluster = a;
                      }));
                Belt_Option.map(onMapReady, (function (cb) {
                        return Curry._1(cb, m);
                      }));
              });
          return (function (param) {
                    Belt_Option.map(mapRef.contents, (function (map) {
                            Belt_Option.map(onMapDestroy, (function (cb) {
                                    return Curry._1(cb, map);
                                  }));
                            map.destroy();
                          }));
                    initialized.current = false;
                  });
        }), [
        id,
        onMapReady,
        onMapDestroy,
        annotationForCluster
      ]);
}

function AppleMap$MarkerAnnotation(Props) {
  return null;
}

var MarkerAnnotation = {
  make: AppleMap$MarkerAnnotation
};

function AppleMap(Props) {
  var id = Props.id;
  var widthOpt = Props.width;
  var heightOpt = Props.height;
  var onMapReady = Props.onMapReady;
  var onMapDestroy = Props.onMapDestroy;
  var annotationForCluster = Props.annotationForCluster;
  var children = Props.children;
  var width = widthOpt !== undefined ? widthOpt : "100%";
  var height = heightOpt !== undefined ? heightOpt : "100%";
  var cleanups = React.useRef([]);
  var handleMapReady = React.useCallback((function (map) {
          Belt_Option.map(onMapReady, (function (cb) {
                  Curry._1(cb, map);
                }));
          cleanups.current = Belt_Array.concat(cleanups.current, Belt_Option.getWithDefault(Belt_Option.map(children, (function (childs) {
                          return Belt_Array.map(React.Children.toArray(childs), (function (child) {
                                        var match = Belt_Option.flatMap(child.type, (function (prim) {
                                                return prim.name;
                                              }));
                                        var match$1 = child.props;
                                        if (match === undefined) {
                                          return ;
                                        }
                                        if (match !== "AppleMap$MarkerAnnotation") {
                                          return ;
                                        }
                                        if (match$1 === undefined) {
                                          return ;
                                        }
                                        var props = Caml_option.valFromOption(match$1);
                                        var annotation = new (mapkit.MarkerAnnotation)(props.coordinate, props.options);
                                        annotation.addEventListener("select", props.onPress);
                                        map.addAnnotation(annotation);
                                        return (function (param) {
                                                  map.removeAnnotation(annotation);
                                                });
                                      }));
                        })), []));
        }), [
        children,
        onMapReady
      ]);
  var handleMapDestroy = React.useCallback((function (map) {
          Belt_Option.map(onMapDestroy, (function (cb) {
                  Curry._1(cb, map);
                }));
        }), [onMapDestroy]);
  useMap(id, handleMapReady, handleMapDestroy, annotationForCluster, undefined);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  id: id,
                  style: {
                    height: height,
                    width: width
                  }
                }));
}

var defaultCoords = {
  latitude: 46.9,
  longitude: 2.179
};

var defaultRegionSpan = {
  latitudeDelta: 12,
  longitudeDelta: 12
};

var zoomRegionSpan = {
  latitudeDelta: 0.1,
  longitudeDelta: 0.1
};

var make = AppleMap;

export {
  token ,
  initialised ,
  ensureInitialized ,
  useMap ,
  defaultCoords ,
  defaultRegionSpan ,
  zoomRegionSpan ,
  MarkerAnnotation ,
  make ,
}
/* token Not a pure module */
