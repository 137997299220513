// Generated by ReScript, PLEASE EDIT WITH CARE

import * as T from "../T.bs.js";
import * as User from "../User.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as PlacesMap from "../components/shareable/PlacesMap.bs.js";
import * as NextIntl from "next-intl";
import Head from "next/head";
import * as ReactNative from "react-native";
import * as ViewContained from "../components/shareable/ViewContained.bs.js";
import * as WebsiteFooter from "../components/WebsiteFooter.bs.js";
import * as WebsiteHeader from "../components/WebsiteHeader.bs.js";
import * as WebsiteWrapper from "../components/WebsiteWrapper.bs.js";
import * as Style$ReactNative from "rescript-react-native/src/apis/Style.bs.js";
import * as Font$ReactMultiversal from "react-multiversal/src/Font.bs.js";
import * as Spacer$ReactMultiversal from "react-multiversal/src/Spacer.bs.js";
import * as Predefined$ReactMultiversal from "react-multiversal/src/Predefined.bs.js";
import * as SpacedView$ReactMultiversal from "react-multiversal/src/SpacedView.bs.js";

function PageIndex(Props) {
  var theme = Curry._2(T.useTheme, undefined, undefined);
  var t = NextIntl.useTranslations();
  var match = User.useOptionalUserQueryRef(undefined);
  var userOptionalQueryRef = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var mounted_set = match$1[1];
  React.useEffect((function () {
          Curry._1(mounted_set, (function (param) {
                  return true;
                }));
        }), []);
  return React.createElement(WebsiteWrapper.make, {
              userOptionalQueryRef: userOptionalQueryRef,
              children: null
            }, React.createElement(Head, {
                  children: null
                }, React.createElement("title", undefined, t("Index-Meta-title")), React.createElement("meta", {
                      content: t("Index-Meta-description"),
                      name: "description"
                    })), React.createElement(WebsiteHeader.make, {
                  userOptionalQueryRef: userOptionalQueryRef
                }), React.createElement(ReactNative.View, {
                  style: [
                    Predefined$ReactMultiversal.styles.flexGrow,
                    theme.styles.back
                  ],
                  children: null
                }, React.createElement(ReactNative.ImageBackground, {
                      source: {
                        uri: "/andy-cheetham-IWnpFDTsJ6s-unsplash.jpg"
                      },
                      style: {
                        height: 400
                      },
                      children: React.createElement(ViewContained.make, {
                            contentContainerStyle: [Predefined$ReactMultiversal.styles.center],
                            maxWidth: 600,
                            children: React.createElement(SpacedView$ReactMultiversal.make, {
                                  horizontal: /* S */4,
                                  children: React.createElement(ReactNative.Text, {
                                        children: t("Index-Baseline"),
                                        style: [
                                          Font$ReactMultiversal.iosEm.largeTitle,
                                          T.themeDark.styles.text,
                                          {
                                            textAlign: "center",
                                            textShadowColor: "#000",
                                            textShadowRadius: 20
                                          }
                                        ]
                                      })
                                })
                          })
                    }), React.createElement(ReactNative.View, {
                      style: {
                        height: 800,
                        width: Style$ReactNative.pct(100)
                      },
                      children: React.createElement(ReactNative.View, {
                            style: ReactNative.StyleSheet.absoluteFill,
                            children: match$1[0] ? React.createElement(PlacesMap.make, {}) : null
                          })
                    }), React.createElement(Spacer$ReactMultiversal.make, {
                      size: /* L */2
                    })), React.createElement(WebsiteFooter.make, {}));
}

var make = PageIndex;

var $$default = PageIndex;

export {
  make ,
  $$default ,
  $$default as default,
}
/* T Not a pure module */
