// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_promise from "rescript/lib/es6/js_promise.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRelay from "react-relay";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as Address_address_graphql from "./__generated__/Address_address_graphql.bs.js";
import * as Address_get_Query_graphql from "./__generated__/Address_get_Query_graphql.bs.js";
import * as Address_getById_Query_graphql from "./__generated__/Address_getById_Query_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(Address_address_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(Address_address_graphql.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = ReactRelay.useFragment(Address_address_graphql.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return RescriptRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return Address_address_graphql.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

function readInline(fRef) {
  return Address_address_graphql.Internal.convertFragment(ReactRelay.readInlineData(Address_address_graphql.node, fRef));
}

var AddressFragment = {
  Types: undefined,
  Operation: undefined,
  use: use,
  useOpt: useOpt,
  readInline: readInline
};

function use$1(variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  var data = ReactRelay.useLazyLoadQuery(Address_getById_Query_graphql.node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(Address_getById_Query_graphql.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return RescriptRelay_Internal.internal_useConvertedValue(Address_getById_Query_graphql.Internal.convertResponse, data);
}

function useLoader(param) {
  var match = ReactRelay.useQueryLoader(Address_getById_Query_graphql.node);
  var loadQueryFn = match[1];
  var loadQuery = React.useMemo((function () {
          return function (param, param$1, param$2, param$3) {
            return Curry._2(loadQueryFn, Address_getById_Query_graphql.Internal.convertVariables(param), {
                        fetchPolicy: param$1,
                        networkCacheConfig: param$2
                      });
          };
        }), [loadQueryFn]);
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  ReactRelay.fetchQuery(environment, Address_getById_Query_graphql.node, Address_getById_Query_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            Curry._1(onResult, {
                  TAG: /* Ok */0,
                  _0: Address_getById_Query_graphql.Internal.convertResponse(res)
                });
          }),
        error: (function (err) {
            Curry._1(onResult, {
                  TAG: /* Error */1,
                  _0: err
                });
          })
      });
}

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  var __x = ReactRelay.fetchQuery(environment, Address_getById_Query_graphql.node, Address_getById_Query_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).toPromise();
  return Js_promise.then_((function (res) {
                return Promise.resolve(Address_getById_Query_graphql.Internal.convertResponse(res));
              }), __x);
}

function usePreloaded(queryRef, param) {
  var data = ReactRelay.usePreloadedQuery(Address_getById_Query_graphql.node, queryRef);
  return RescriptRelay_Internal.internal_useConvertedValue(Address_getById_Query_graphql.Internal.convertResponse, data);
}

function retain(environment, variables) {
  var operationDescriptor = RelayRuntime.createOperationDescriptor(Address_getById_Query_graphql.node, Address_getById_Query_graphql.Internal.convertVariables(variables));
  return environment.retain(operationDescriptor);
}

var GetQueryById = {
  Operation: undefined,
  Types: undefined,
  use: use$1,
  useLoader: useLoader,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  usePreloaded: usePreloaded,
  retain: retain
};

function useQueryRef_addressById(id) {
  var match = useLoader(undefined);
  var disposeQuery = match[2];
  var loadQuery = match[1];
  var refreshQuery_address = React.useCallback((function (param) {
          Curry._4(loadQuery, {
                id: id
              }, /* NetworkOnly */3, undefined, undefined);
        }), [
        id,
        loadQuery
      ]);
  React.useEffect((function () {
          Curry._1(refreshQuery_address, undefined);
          return (function (param) {
                    Curry._1(disposeQuery, undefined);
                  });
        }), [
        refreshQuery_address,
        disposeQuery
      ]);
  return match[0];
}

function use$2(variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  var data = ReactRelay.useLazyLoadQuery(Address_get_Query_graphql.node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(Address_get_Query_graphql.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return RescriptRelay_Internal.internal_useConvertedValue(Address_get_Query_graphql.Internal.convertResponse, data);
}

function useLoader$1(param) {
  var match = ReactRelay.useQueryLoader(Address_get_Query_graphql.node);
  var loadQueryFn = match[1];
  var loadQuery = React.useMemo((function () {
          return function (param, param$1, param$2, param$3) {
            return Curry._2(loadQueryFn, Address_get_Query_graphql.Internal.convertVariables(param), {
                        fetchPolicy: param$1,
                        networkCacheConfig: param$2
                      });
          };
        }), [loadQueryFn]);
  return [
          Caml_option.nullable_to_opt(match[0]),
          loadQuery,
          match[2]
        ];
}

function $$fetch$1(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  ReactRelay.fetchQuery(environment, Address_get_Query_graphql.node, Address_get_Query_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            Curry._1(onResult, {
                  TAG: /* Ok */0,
                  _0: Address_get_Query_graphql.Internal.convertResponse(res)
                });
          }),
        error: (function (err) {
            Curry._1(onResult, {
                  TAG: /* Error */1,
                  _0: err
                });
          })
      });
}

function fetchPromised$1(environment, variables, networkCacheConfig, fetchPolicy, param) {
  var __x = ReactRelay.fetchQuery(environment, Address_get_Query_graphql.node, Address_get_Query_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).toPromise();
  return Js_promise.then_((function (res) {
                return Promise.resolve(Address_get_Query_graphql.Internal.convertResponse(res));
              }), __x);
}

function usePreloaded$1(queryRef, param) {
  var data = ReactRelay.usePreloadedQuery(Address_get_Query_graphql.node, queryRef);
  return RescriptRelay_Internal.internal_useConvertedValue(Address_get_Query_graphql.Internal.convertResponse, data);
}

function retain$1(environment, variables) {
  var operationDescriptor = RelayRuntime.createOperationDescriptor(Address_get_Query_graphql.node, Address_get_Query_graphql.Internal.convertVariables(variables));
  return environment.retain(operationDescriptor);
}

var GetQuery = {
  Operation: undefined,
  Types: undefined,
  use: use$2,
  useLoader: useLoader$1,
  $$fetch: $$fetch$1,
  fetchPromised: fetchPromised$1,
  usePreloaded: usePreloaded$1,
  retain: retain$1
};

function useQueryRef_addresses(param) {
  var match = useLoader$1(undefined);
  var disposeQuery = match[2];
  var loadQuery = match[1];
  React.useEffect((function () {
          Curry._4(loadQuery, undefined, /* NetworkOnly */3, undefined, undefined);
          return (function (param) {
                    Curry._1(disposeQuery, undefined);
                  });
        }), [
        loadQuery,
        disposeQuery
      ]);
  return match[0];
}

export {
  AddressFragment ,
  GetQueryById ,
  useQueryRef_addressById ,
  GetQuery ,
  useQueryRef_addresses ,
}
/* react Not a pure module */
